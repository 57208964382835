.siderbar {
  display: flex;
  flex-direction: column;
  /* background-color: #115088 !important; */
  background-color: #d4f5d4;
  position: relative;
  height: 100vh;
}

.menu-scroll-container {
  /* background-color: #115088; */
  background-color: #d4f5d4;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  flex: 1;
}

.ant-menu-item {
  font-weight: 500 !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 13px;
  /* color: #f5f3f0 !important; */
  color: #2b662b !important;
}

.ant-menu-item-selected,
.ant-menu-item-active {
  /* background-color: #f5f3f0 !important; */
  background-color: white !important;
  color: #2b662b !important;
}

.logout-section {
  margin-top: auto;
  position: sticky;
  bottom: 0;
}

.content {
  /* background-color: rgb(239, 239, 239); */
  background-color: white;
  overflow: auto;
  height: 100vh;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.top-cus-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* background-color: #115088; */
  /* background-color: #399e39; */
  background: linear-gradient(to right, #82e082, #226aa9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 50px;
  margin-right: 10px;
}

.com-title {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-weight: 500 !important;
  color: white;
}

@media (max-width: 1024px) {

  .siderbar .cus-prof-name,
  .siderbar .mr-4 {
    display: none;
  }

  .siderbar .p-3 {
    justify-content: flex-end;
  }
}