@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
}

.com-heading {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  font-weight: 500 !important;
  color: #2b662b;
}

.form-heading {
  font-family: 'Inter', sans-serif !important;
  font-size: 14.5px;
  font-weight: 500 !important;
  color: #2b662b;
}

.ant-input-outlined:focus-within, .ant-input-outlined:hover {
  border-color: burlywood;
}

.ant-select-focused .ant-select-selector,
.ant-select:hover .ant-select-selector {
  border-color: burlywood !important;
}

.ant-picker-outlined:focus-within, .ant-picker-outlined:hover {
  border-color: burlywood;
}

.cus-content {
  font-family: 'Inter', sans-serif !important;
  font-size: 15px;
  font-weight: 500 !important;
  color: #2b662b;
}

.cus-btn {
  font-family: 'Inter', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border-color: #2b662b !important;
  background-color: white;
  color: #2b662b;
  height: 30px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.cus-btn:hover {
  font-family: 'Inter', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background-color: #53d153 !important;
  color: white !important;
  border-style: solid;
  border-width: 1px;
  border-color: #53d153 !important;
  height: 30px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.ant-table-thead th {
  font-family: 'Inter', sans-serif !important;
  font-size: 14px !important;
  background-color: #d4f5d4 !important;
  color: black !important;
}
.ant-table {
  border: 1px solid #e8e2e2;
}

.ant-radio-checked .ant-radio-inner {
  border-color: green !important;
  background-color: green !important;
}

.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-inner:hover {
  border-color: green !important;
  box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.2) !important;
}

.ant-radio-wrapper-checked .ant-radio + span {
  color: green !important;
}

.ant-radio-inner {
  border-color: green !important;
}

.ant-radio-wrapper-checked span {
  color: green !important;
}

.form-sec-heading {
  font-family: 'Inter', sans-serif !important;
  font-size: 15px;
  font-weight: 500 !important;
  color: #2b662b;
  background-color: #d4f5d4;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}
