.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: linear-gradient(135deg, #d4f5d4, #a8e6cf); */
  /* padding: 1rem;
  position: relative;
  overflow: hidden;
  filter: brightness(0.9); */
  background-image: url('../../assests/backgnd.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-card {
  /* background-color: #d4f5d4; */
  background-color: rgba(212, 245, 212, 0.4); /* Light green with 80% opacity */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  min-height: 350px;
}
.login-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.company-name {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: bold;
  /* color: #2b662b; */
  color: white;
  margin-left: 0.75rem;
}
.login-logo img {
  height: 48px;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3)); 
}
.form-label {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  /* color: #2b662b; */
  color: white;
  margin-bottom: 0.5rem;
}
.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #8bc48b;
  border-radius: 4px;
  margin-top: 0.5rem;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  background-color: #f5fff5;
}

.form-input:focus {
  outline: none;
  border-color: #5aa75a;
  box-shadow: 0 0 0 1px #5aa75a;
}

.error-message {
  color: #d9534f;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  margin-top: 0.25rem;
}
.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: white;
  color: #2b662b;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 1rem;
  transition: background-color 0.2s ease;
}

.login-button:hover {
  background-color: #53d153;
  color: white;
}

@media (max-width: 768px) {
  .login-card {
    padding: 1.5rem;
    max-width: 90%;
  }

  .form-label {
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    color: white;
  }

  .form-input {
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    padding: 0.5rem;
  }

  .login-button {
    padding: 0.5rem;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
  }

  .login-logo img {
    height: 40px;
  }
}

@media (max-width: 480px) {
  .login-card {
    padding: 1rem;
    max-width: 95%;
  }

  .form-input {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
  }

  .login-button {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
  }
}
